import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Main from '@/views/Main'


Vue.use(VueRouter)

export const asyncRoutes = [
  //默认
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/shop",
    redirect: "/shop/shopList",
  },
  {
    path: "/goods",
    redirect: "/goods/goodsList",
  },
  {
    path: "/goods",
    redirect: "/goods/goodsList",
  },
  {
    path: "/order",
    redirect: "/order/orderAll",
  },
  {
    path: "/account",
    redirect: "/account/account",
  },
  {
    path: "/OperationLog",
    redirect: "/OperationLog/OperationLog",
  },
  {
    path: "/stock",
    redirect: "/stock/goodsStock/freshgoodsStock",
  },
  //登录
  {
    path: "/login",
    component: Login,
  },
   //后台首页
   {
    path: "",
    component: Main,
    children: [
      {
        path: "/home",
        component: () => import('@/views/home/Home.vue')
      },
      {
        path: "/changePwd",
        component: () => import('@/views/home/ChangePwd.vue')
      },
    ],
  },
  
  //门店管理
  {
    path: "/shop",
    component: Main,
    children: [
      {
        path: "/shop/shopList",
        component: () => import('@/views/shop/ShopList.vue')
      },
      {
        path: "/shop/shopSett",
        component: () => import('@/views/shop/ShopSett.vue')
      },
      {
        path: "/shop/census",
        component: () => import('@/views/shop/Census.vue')
      },
      {
        path: "/shop/shopMember",
        component: () => import('@/views/shop/ShopMember.vue')
      },
      {
        path: "/shop/shopBlacklist",
        component: () => import('@/views/shop/ShopBlacklist.vue')
      }
    ],
  },
  //商品管理
  {
    path: "/goods",
    component: Main,
    children: [
      {
        path: "/goods/goodsList",
        component: () => import('@/views/goods/GoodsList.vue')
      },
      {
        path: "/goods/changeGoods",
        component: () => import('@/views/goods/ChangeGoods.vue')
      },
      {
        path: "/goods/goodsStatistics",
        component: () => import('@/views/goods/GoodsStatistics.vue')
      },
      ///////////连锁超市
      {
        path: "/goods/storeGoods",
        component: () => import('@/views/goods/storeGoods.vue')
      },
      {
        path: "/goods/storeGoodsType",
        component: () => import('@/views/goods/storeGoodsType.vue')
      },
    ],
  },
  {
    path: "/goods2",
    redirect: "/goods/storeGoodsType",
  },
  //订单管理
  {
    path: "/order",
    component: Main,
    children: [
      {
        path: "/order/orderAll",
        component: () => import('@/views/order/OrderAll.vue')
      },
      {
        path: "/order/unclaimed",
        component: () => import('@/views/order/Unclaimed.vue')
      },
      {
        path: "/order/received",
        name:"/order/received",
        component: () => import('@/views/order/Received.vue')
      }, {
        path: "/order/refunded",
        component: () => import('@/views/order/Refunded.vue')
      },
      {
        path: "/order/orderWarn",
        component: () => import('@/views/order/OrderWarn.vue')
      },
      {
        path: "/order/orderTabel",
        component: () => import('@/views/order/orderTabel.vue')
      },
      {
        path: "/order/outGoods",
        name:"/order/outGoods",
        component: () => import('@/views/order/OutGoods.vue')
      },
    ],
  },
  //账号管理
  {
    path: "/account",
    component: Main,
    children: [
      {
        path: "/account/account",
        component: () => import('@/views/account/account.vue')
      },
    ],
  },
   //日志管理
   {
    path: "/OperationLog",
    component: Main,
    children: [
      {
        path: "/OperationLog/OperationLog",
        component: () => import('@/views/OperationLog/OperationLog.vue')
      },
    ],
  },
  //结算管理
  {
    path: "/check",
    component: Main,
    children: [
      {
        path: "/check/checkAcc",
        component: () => import('@/views/check/CheckAcc.vue')
      },
      {
        path: "/check/checkInfo",
        component: () => import('@/views/check/CheckInfo.vue')
      },
      {
        path: "/check/checkList",
        component: () => import('@/views/check/CheckList.vue')
      },
      {
        path: "/check/outGoods",
        component: () => import('@/views/check/OutGoods.vue')
      },
    ],
  },
  //库存管理
  {
    path: "/stock",
    component: Main,
    children: [
      {
        path: "/stock/goodsStock/freshgoodsStock",
        component: () => import('@/views/stock/goodsStock/freshGoodsStock.vue')
      },
      {
        path: "/stock/goodsStock/NofreshgoodsStock",
        component: () => import('@/views/stock/goodsStock/NofreshGoodsStock.vue')
      },
      //非生鲜出库记录
      {
        path: "/stock/outStock/outStock",
        component: () => import('@/views/stock/outStock/OutStock.vue')
      },
      //生鲜出库记录
      {
        path: "/stock/outStock/freshoutStock",
        component: () => import('@/views/stock/outStock/freshOutStock.vue')
      },
      {
        path: "/stock/supermarketGoodsEdit",
        component: () => import('@/views/stock/supermarketGoodsEdit.vue')
      },
      {
        path: "/stock/yesterdayGood",
        component: () => import('@/views/stock/yesterdayGood.vue')
      },
      
      ////////////////////////////普通连锁/////////////
      {
        path: "/stock/changeStock1",
        component: () => import('@/views/stock/store/ChangeStock.vue')
      },
      {
        path: "/stock/goodsStock1",
        component: () => import('@/views/stock/store/GoodsStock.vue')
      },
      {
        path: "/stock/outStock1",
        component: () => import('@/views/stock/store/OutStock.vue')
      },
      
    ],
  },
 
  {
    path: "/stock2",
    redirect: "/stock/goodsStock/goodsStock2",
  },
  ///////////////////////////门店路由/////////////////
  {
    path: "/goods1",
    redirect: "/goods/goodsList1",
  },
  {
    path: "/stock1",
    redirect: "/stock/goodsStock2",
  },
   //订单管理
   {
    path: "/order1",
    component: Main,
    children: [
      {
        path: "/order/orderAll1",
        component: () => import('@/views/store/order/OrderAll.vue')
      },
      {
        path: "/order/unclaimed1",
        component: () => import('@/views/store/order/Unclaimed.vue')
      },
      {
        path: "/order/received1",
        component: () => import('@/views/store/order/Received.vue')
      }, {
        path: "/order/refunded1",
        component: () => import('@/views/store/order/Refunded.vue')
      },
      {
        path: "/order/orderTabel1",
        component: () => import('@/views/store/order/orderTabel.vue')
      },
      {
        path: "/order/outGoods1",
        component: () => import('@/views/store/order/OutGoods.vue')
      },
    ],
  },
  //商品管理
  {
    path: "/goods1",
    component: Main,
    children: [
      {
        path: "/goods/goodsList1",
        component: () => import('@/views/store/goods/GoodsList.vue')
      },
      //系列商品列表,懒得改,将就用的
      {
        path: "/goods/changeGoods1",
        component: () => import('@/views/store/goods/ChangeGoods.vue')
      },
    ],
  },
 
  //库存管理
  {
    path: "/stock2",
    component: Main,
    children: [
      {
        path: "/stock/goodsStock/goodsStock2",
        component: () => import('@/views/store/stock/goodsStock/GoodsStock.vue')
      },
      {
        path: "/stock/goodsStock/NoGoodsStock2",
        component: () => import('@/views/store/stock/goodsStock/NoGoodsStock.vue')
      },
      // {
      //   path: "/stock/changeStock2",
      //   component: () => import('@/views/store/stock/ChangeStock.vue')
      // },
      //非生鲜
      {
        path: "/stock/outStock/freshoutStock2",
        component: () => import('@/views/store/stock/outStock/freshOutStock.vue')
      },
      //生鲜
      {
        path: "/stock/outStock/outStock2",
        component: () => import('@/views/store/stock/outStock/OutStock.vue')
      },
      {
        path: "/stock/yesterdayGood2",
        component: () => import('@/views/store/stock/yesterdayGood.vue')
      },
    ],
  },
]

const router = new VueRouter({
  routes: asyncRoutes
})

router.beforeEach((to,from,next)=>{
  let tk=localStorage.tk
  if(to.path==="/login"){
    next()
  }else if(tk){
    next()
  }else if(!tk){
    router.push("/")
  } 
})


export default router
