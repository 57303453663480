// 封装本地存储的工具js

export default {
    // 存
    set(key,value){
        return localStorage.setItem(key,value);
    },
    // 取
    get(key){
        return localStorage.getItem(key);
    },
    // 删一个
    remove(key){
        return localStorage.removeItem(key)
    },
    // 清空
    clear(){
        return localStorage.clear();
    }
}

