/*
    axios第一层封装
*/
import axios from "axios"
// import { Message } from 'element-ui';
import router from "../router";
import local from "./local.js"
import {httpUrl} from "./http.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue'

axios.defaults.baseURL = httpUrl;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let tk = local.get('tk')
    let chainAccountId=local.get('chainAccountId')//自己存一个公用的参数，放在所有请求头里面
    if (tk) {
        config.headers = {
            "Authorization":"Bearer "+ tk,
            'Content-Type': 'application/json; charset=UTF-8',
            'X-accountId':chainAccountId
        };
    }

    return config;
},
function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if( response.data.code || response.data.msg ){
        let {code,msg} = response.data;
       
    }
    if(response.data.code==401){
        router.push("/")
    }
    if(response.data.code!=0 &&response.data.code){
        ElementUI.Message.error(response.data.msg)
    }
    return response;
}, function (error) {
    console.log('error',error)
    // 对响应错误做点什么
    if(error.response.data.code===401){
        this.router.push("/")
    }else{
        ElementUI.Message.error(error.response.data.msg)
    }
    return Promise.reject(error);
});

export default axios